<template>
  <a-spin :spinning="spinning">
    <div class="preview">
      <div class="modal-header">
        <div class="modal-title">确认合同内容</div>
      </div>

      <div class="p-w">
        <div class="p-l">
          <div class="pc-w" :style="{ height: oHeight + 'px' }">
            <div v-if="this.templateContent != ''" class="content" v-html="this.templateContent"></div>
            <div style="height: 100%" v-if="this.templateContent == ''" class="content">
              <iframe style="height: 100%; width: 100%; border: 0" :src="baseFileUrl + pdfTemplateUrl"></iframe>
            </div>
          </div>

          <div class="fn-w">
            <a-button type="primary" size="default" @click="_contractPreview">{{
              l("预览合同")
            }}</a-button>
            <a-button type="primary" size="default" @click="_sendToSign">{{
              l("发起签署")
            }}</a-button>
          </div>
        </div>
        <div class="p-r" :style="{ height: oHeight + 'px' }">
          <div class="t">确认合同变量信息</div>

          <div class="item-w" v-for="(item, index) in fields" :key="index">
            <div class="iw-l">
              <a-tooltip>
                <template slot="title"> {{ item.fieldDisplayName }}: </template>
                {{ item.fieldDisplayName }}:
              </a-tooltip>
            </div>
            <div class="iw-r">
              <a-input v-if="item.oFieldType === 'commonInput'" :disabled="!item.isCanEdit" v-model="item.fieldValue"
                size="small" style="width: 100%" allowClear :placeholder="l(
                  item.isCanEdit
                    ? `请输入${item.fieldDisplayName}`
                    : '不可编辑'
                )
                  " />
              <a-input-number v-if="item.oFieldType === 'numberInput'" :disabled="!item.isCanEdit"
                v-model="item.fieldValue" size="small" style="width: 100%" :placeholder="l(
                  item.isCanEdit
                    ? `请输入${item.fieldDisplayName}`
                    : '不可编辑'
                )
                  " />
              <a-date-picker v-if="item.oFieldType === 'datePicker'" :disabled="!item.isCanEdit"
                v-model="item.fieldValue" size="small" style="width: 100%" :placeholder="l(
                  item.isCanEdit
                    ? `请选择${item.fieldDisplayName}`
                    : '不可编辑'
                )
                  " />
              <a-range-picker v-if="item.oFieldType === 'rangeDatePicker'" :disabled="!item.isCanEdit"
                v-model="item.fieldValue" :placeholder="['开始日期', '结束日期']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                size="small" style="width: 100%" />
              <a-checkbox-group v-if="item.oFieldType === 'checkbox'" :disabled="!item.isCanEdit" size="small"
                style="width: 100%">
                <a-row>
                  <a-col>
                    <a-checkbox :value="it.value" v-for="(it, i) in item.fieldValue" :key="i">
                      {{ it.label }}
                    </a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
              <a-radio-group v-if="item.oFieldType === 'radio'" :disabled="!item.isCanEdit" size="small"
                style="width: 100%">
                >
                <a-radio :value="it.value" v-for="(it, i) in item.fieldValue" :key="i">
                  {{ it.label }}
                </a-radio>
              </a-radio-group>
              <a-switch v-if="item.oFieldType === 'switch'" :disabled="!item.isCanEdit" v-model="item.fieldValue"
                size="small" />
              <a-upload v-if="item.oFieldType === 'upload'" :action="item.uploadUrl" :multiple="true"
                :list-type="item.listType || 'text'" :data="{ subdir: item.subdirName }" :file-list="item.value"
                :showUploadList="{
                  showPreviewIcon: true,
                  showRemoveIcon:
                    item.showRemoveIcon && item.showRemoveIcon === '0'
                      ? false
                      : true,
                }">
              </a-upload>
            </div>
          </div>

          <div class="btn-w">
            <a-button type="primary" size="small" @click="fill">{{
              l("填充合同")
            }}</a-button>
            <a-button size="small" @click="reset">{{ l("重置合同") }}</a-button>
          </div>

          <div class="tips">
            注意：填充合同之后。若要修改合同信息，必须先重置合同，然后重新填写信息。
          </div>
        </div>
      </div>
      <div class="p-b"></div>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import { abpService } from "@/shared/abp";
import moment from "moment";
import checkAgeFunc from '@/components/PersonAgeTip/check.js';

export default {
  name: "preview",
  mixins: [AppComponentBase, ModalComponentBase],
  props: [],
  components: {},
  data () {
    return {
      spinning: false,
      commonService: null,
      mobileService: null,
      submitParams: {
        name: "",
      },
      fields: [],
      templateContent: "",
      pdfTemplateUrl: "",
      basePdfTemplateUrl: "",
      oTemplateContent: "",
      oHeight: "",
      fieldsList: [],
      baseFileUrl: "",
    };
  },
  computed: {},
  created () {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.mobileService = new CommonServiceProxy(
      AppConsts.mobileApiBaseUrl,
      this.$api
    );
    this.oHeight = window.innerHeight - 200;
    this.baseFileUrl = AppConsts.uploadBaseUrl;
  },
  mounted () {
    this._getSignDataSource();
  },
  methods: {
    async _getSignDataSource () {
      this.spinning = true;
      try {
        let res = await this.mobileService.get(
          {
            url: "/api/Unnatural/getSignDataSource",
            params: {
              projectAgreementId: this.projectAgrementId,
              personnelId: this.personnelId,
            },
          },
          {
            WechatAppletTenantId:
              abpService.abp.multiTenancy.getTenantIdCookie() || "",
          }
        );
        if (res.success && res.statusCode === 200) {
          this.fieldsList = res.data;
        } else {
          this.$notification["warning"]({
            message: "提示",
            description: `${res.message}， 无法填充`,
          });
        }
        this._getProjectAgrementSignDetail(res.data.length ? res.data : []);
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    fill () {
      if (this.templateContent == "") {
        this._speContractFill();
      } else {
        let flag = false;
        for (let i = 0; i < this.fields.length; i++) {
          if (
            !!this.fields[i].fieldValue !== true &&
            this.fields[i].isCanEdit === true
          ) {
            this.$notification["warning"]({
              message: "提示",
              description: `${this.fields[i].fieldDisplayName}不能为空`,
            });
            flag = true;
            break;
          }
        }
        if (flag) return;
        try {
          let templateContent = this.templateContent;
          let fields = JSON.parse(JSON.stringify(this.fields))
          let arr = fields.filter(
            (item) =>
              this.isCanEdit(item.fieldKey) === true ||
              (this.isCanEdit(item.fieldKey) === false && !!item.fieldValue)
          );
          arr.map((item) => {
            if (
              this.isCanEdit(item.fieldKey) === true ||
              (this.isCanEdit(item.fieldKey) === false && !!item.fieldValue)
            ) {
              let fieldKey = item.fieldKey;
              let fieldValue;
              if (item.oFieldType === "datePicker") {
                fieldValue = moment(item.fieldValue).format("YYYY-MM-DD");
              } else if (item.oFieldType === "rangeDatePicker") {
                fieldValue = `${item.fieldValue[0]}至${item.fieldValue[1]}`;
              } else {
                fieldValue = item.fieldValue;
              }

              let withoutGf = new RegExp(
                `\\<u class="gf-inserted" style="(.+?)\\"><span`,
                "g"
              );
              let withoutFf = new RegExp(
                `\\<u class="ff-inserted" style="(.+?)\\"><span`,
                "g"
              );
              templateContent = templateContent.replace(
                withoutGf,
                `<u class="gf-inserted"><span`
              );
              templateContent = templateContent.replace(
                withoutFf,
                `<u class="ff-inserted"><span`
              );

              let gfReg = new RegExp(
                `\\<u class="gf-inserted"><span class="${fieldKey}"(.+?)\\</u>`,
                "g"
              );
              let ffReg = new RegExp(
                `\\<u class="ff-inserted"><span class="${fieldKey}"(.+?)\\</u>`,
                "g"
              );
              templateContent = templateContent.replace(
                gfReg,
                `<u class="gf-inserted"><span class="${fieldKey}">${fieldValue}</span></u>`
              );
              templateContent = templateContent.replace(
                ffReg,
                `<u class="ff-inserted"><span class="${fieldKey}">${fieldValue}</span></u>`
              );
            }
          });
          // console.log(templateContent);
          this.templateContent = templateContent;
          this.$notification["success"]({
            message: "提示",
            description: `填充成功`,
          });
        } catch (error) {
          console.log(error);
          this.$notification["warning"]({
            message: "提示",
            description: `填充失败`,
          });
        }
      }
    },
    reset () {
      this.fields.forEach((item) => {
        if (item.isCanEdit) {
          item.fieldValue = "";
        }
      });
      this.templateContent = this.oTemplateContent;
      this.pdfTemplateUrl = this.basePdfTemplateUrl;
    },
    async _sendToSign () {
      this.spinning = true;
      try {
        const params = {
          projectAgreementId: this.projectAgrementId,
          personnelIds: [this.personnelId]
        };
        await checkAgeFunc(params, (res) => {
          if (res) {
            this.realSendSign();
          }
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.spinning = false;
      }
    },
    async realSendSign () {
      this.spinning = true;
      const opts = {
        userId: this.personnelId,
        templateGuid: this.agreementTemplateId,
        projectAgreementId: this.projectAgrementId,
        fields: this.fields.map((v) => {
          if (v.oFieldType === "datePicker") {
            return {
              fieldKey: v.fieldKey,
              fieldDisplayName: v.fieldDisplayName,
              fieldValue: this.isCanEdit(v.fieldKey)
                ? moment(v.fieldValue).format("YYYY-MM-DD")
                : null,
            };
          }
          if (v.oFieldType === "rangeDatePicker") {
            return {
              fieldKey: v.fieldKey,
              fieldDisplayName: v.fieldDisplayName,
              fieldValue: this.isCanEdit(v.fieldKey)
                ? v.fieldValue.join("至")
                : '',
              // fieldValue: this.isCanEdit(v.fieldKey) ? v.fieldValue : "",
            };
          }
          return {
            fieldKey: v.fieldKey,
            fieldDisplayName: v.fieldDisplayName,
            fieldValue: this.isCanEdit(v.fieldKey) ? v.fieldValue : null,
          };
        }),
      };
      try {
        // console.log(opts);
        let res = await this.commonService.post({
          url: `/api/services/app/UnnaturalPersonProjectAgreement/SendToSign`,
          params: { ...opts },
        });
        this.$notification["success"]({
          message: "提示",
          description: `发起签署成功`,
        });
        this.close();
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _contractPreview () {
      this.spinning = true;
      let fields = JSON.parse(JSON.stringify(this.fields))
      const arr = fields.filter(
        (item) =>
          this.isCanEdit(item.fieldKey) === true ||
          (this.isCanEdit(item.fieldKey) === false && !!item.fieldValue)
      );
      let fls = arr.map((item) => {
        if (item.oFieldType === "datePicker") {
          item.fieldValue = moment(item.fieldValue).format("YYYY-MM-DD");
        } else if (item.oFieldType === "rangeDatePicker") {
          const arr = [...item.fieldValue];
          item.fieldValue = `${arr[0]}至${arr[1]}`;
        } else {
          item.fieldValue = item.fieldValue;
        }
        return { ...item };
      });
      const opts = {
        userId: this.personnelId,
        templateGuid: this.agreementTemplateId,
        projectAgreementId: this.projectAgrementId,
        fields: fls,
      };
      try {
        let res = await this.commonService.post({
          url:
            "/api/services/app/UnnaturalPersonProjectAgreement/ContractPreview",
          params: { ...opts },
        });
        window.open(`${AppConsts.uploadBaseUrl}${res}`);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _speContractFill () {
      this.spinning = true;
      let fields = JSON.parse(JSON.stringify(this.fields))
      let arr = fields.filter(
        (item) =>
          this.isCanEdit(item.fieldKey) === true ||
          (this.isCanEdit(item.fieldKey) === false && !!item.fieldValue)
      );
      let fls = arr.map((item) => {
        if (item.oFieldType === "datePicker") {
          item.fieldValue = moment(item.fieldValue).format("YYYY-MM-DD");
        } else if (item.oFieldType === "rangeDatePicker") {
          const arr = [...item.fieldValue];
          item.fieldValue = `${arr[0]}至${arr[1]}`;
        } else {
          item.fieldValue = item.fieldValue;
        }
        return { ...item };
      });
      const opts = {
        userId: this.personnelId,
        templateGuid: this.agreementTemplateId,
        projectAgreementId: this.projectAgrementId,
        fields: fls,
      };
      try {
        let res = await this.commonService.post({
          url:
            "/api/services/app/UnnaturalPersonProjectAgreement/ContractPreview",
          params: { ...opts },
        });
        this.pdfTemplateUrl = res;
        // window.open(`${AppConsts.uploadBaseUrl}${res}`);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _getProjectAgrementSignDetail (fieldsList) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url:
            "/api/services/app/UnnaturalPersonProjectAgreement/GetProjectAgrementSignDetail",
          params: {
            projectAgrementId: this.projectAgrementId,
            personnelId: this.personnelId,
          },
        });
        if (res.fields.length) {
          this.fields = res.fields.map((item) => {
            let f = null;
            if (fieldsList.length) {
              f = fieldsList.find((v) => v.key === item.fieldKey);
            }
            if (item.fieldType === 10) {
              return {
                ...item,
                isCanEdit: this.isCanEdit(item.fieldKey),
                fieldValue: f ? f.value.split("至") : item.fieldValue,
                oFieldType: this.returnType(item.fieldType),
              };
            }
            return {
              ...item,
              isCanEdit: this.isCanEdit(item.fieldKey),
              fieldValue: f ? f.value : item.fieldValue,
              oFieldType: this.returnType(item.fieldType),
            };
          });
        }
        if (res.templateContent) {
          this.templateContent = res.templateContent;
          this.oTemplateContent = res.templateContent;
        }
        this.pdfTemplateUrl = res.pdfTemplateUrl;
        this.basePdfTemplateUrl = res.pdfTemplateUrl;
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    isCanEdit (type) {
      switch (type) {
        case "organization_name":
          return false;
        case "personnel_realname":
          return false;
        case "phone_number":
          return false;
        case "idcard":
          return false;
        default:
          return true;
      }
    },
    returnType (t) {
      switch (t) {
        case 1:
          return "commonInput";
        case 3:
          return "numberInput";
        // case 4:
        //   return "commonSelect";
        // case 5:
        //   return "checkbox";
        // case 6:
        //   return "radio";
        // case 7:
        //   return "switch";
        // case 8:
        //   return "upload";
        case 9:
          return "datePicker";
        case 10:
          return "rangeDatePicker";
        default:
          return "commonInput";
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../styles/common.less";

.preview {
  .p-w {
    #flex;

    .p-l {
      padding: 0 10px;
      #flex1;
      height: 100%;

      .pc-w {
        overflow-y: scroll;

        border: 1px solid @color-border-gray;
      }

      .fn-w {
        #tar;
        margin-top: 20px;
      }
    }

    .p-r {
      overflow-y: scroll;
      padding: 0 10px;
      width: 280px;

      .t {
        #fw;
        line-height: 40px;
      }

      .item-w {
        #flex;
        #flex-vc;
        margin-bottom: 10px;

        .iw-l {
          #tal;
          padding-right: 5px;
          // width: 30%;
          width: 68px;
          font-size: 14px;
          #text-overflow;
        }

        .iw-r {
          #flex1;
        }
      }

      .btn-w {
        margin-top: 20px;
      }

      .tips {
        margin-top: 20px;
        font-size: 13px;
        color: @color-lightblack;
      }
    }
  }

  #scrollbarStyle;
}
</style>
