var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"preview"},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[_vm._v("确认合同内容")])]),_c('div',{staticClass:"p-w"},[_c('div',{staticClass:"p-l"},[_c('div',{staticClass:"pc-w",style:({ height: _vm.oHeight + 'px' })},[(this.templateContent != '')?_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(this.templateContent)}}):_vm._e(),(this.templateContent == '')?_c('div',{staticClass:"content",staticStyle:{"height":"100%"}},[_c('iframe',{staticStyle:{"height":"100%","width":"100%","border":"0"},attrs:{"src":_vm.baseFileUrl + _vm.pdfTemplateUrl}})]):_vm._e()]),_c('div',{staticClass:"fn-w"},[_c('a-button',{attrs:{"type":"primary","size":"default"},on:{"click":_vm._contractPreview}},[_vm._v(_vm._s(_vm.l("预览合同")))]),_c('a-button',{attrs:{"type":"primary","size":"default"},on:{"click":_vm._sendToSign}},[_vm._v(_vm._s(_vm.l("发起签署")))])],1)]),_c('div',{staticClass:"p-r",style:({ height: _vm.oHeight + 'px' })},[_c('div',{staticClass:"t"},[_vm._v("确认合同变量信息")]),_vm._l((_vm.fields),function(item,index){return _c('div',{key:index,staticClass:"item-w"},[_c('div',{staticClass:"iw-l"},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(item.fieldDisplayName)+": ")]),_vm._v(" "+_vm._s(item.fieldDisplayName)+": ")],2)],1),_c('div',{staticClass:"iw-r"},[(item.oFieldType === 'commonInput')?_c('a-input',{staticStyle:{"width":"100%"},attrs:{"disabled":!item.isCanEdit,"size":"small","allowClear":"","placeholder":_vm.l(
                item.isCanEdit
                  ? ("请输入" + (item.fieldDisplayName))
                  : '不可编辑'
              )},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}}):_vm._e(),(item.oFieldType === 'numberInput')?_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"disabled":!item.isCanEdit,"size":"small","placeholder":_vm.l(
                item.isCanEdit
                  ? ("请输入" + (item.fieldDisplayName))
                  : '不可编辑'
              )},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}}):_vm._e(),(item.oFieldType === 'datePicker')?_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"disabled":!item.isCanEdit,"size":"small","placeholder":_vm.l(
                item.isCanEdit
                  ? ("请选择" + (item.fieldDisplayName))
                  : '不可编辑'
              )},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}}):_vm._e(),(item.oFieldType === 'rangeDatePicker')?_c('a-range-picker',{staticStyle:{"width":"100%"},attrs:{"disabled":!item.isCanEdit,"placeholder":['开始日期', '结束日期'],"format":"YYYY-MM-DD","valueFormat":"YYYY-MM-DD","size":"small"},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}}):_vm._e(),(item.oFieldType === 'checkbox')?_c('a-checkbox-group',{staticStyle:{"width":"100%"},attrs:{"disabled":!item.isCanEdit,"size":"small"}},[_c('a-row',[_c('a-col',_vm._l((item.fieldValue),function(it,i){return _c('a-checkbox',{key:i,attrs:{"value":it.value}},[_vm._v(" "+_vm._s(it.label)+" ")])}),1)],1)],1):_vm._e(),(item.oFieldType === 'radio')?_c('a-radio-group',{staticStyle:{"width":"100%"},attrs:{"disabled":!item.isCanEdit,"size":"small"}},[_vm._v(" > "),_vm._l((item.fieldValue),function(it,i){return _c('a-radio',{key:i,attrs:{"value":it.value}},[_vm._v(" "+_vm._s(it.label)+" ")])})],2):_vm._e(),(item.oFieldType === 'switch')?_c('a-switch',{attrs:{"disabled":!item.isCanEdit,"size":"small"},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}}):_vm._e(),(item.oFieldType === 'upload')?_c('a-upload',{attrs:{"action":item.uploadUrl,"multiple":true,"list-type":item.listType || 'text',"data":{ subdir: item.subdirName },"file-list":item.value,"showUploadList":{
                showPreviewIcon: true,
                showRemoveIcon:
                  item.showRemoveIcon && item.showRemoveIcon === '0'
                    ? false
                    : true,
              }}}):_vm._e()],1)])}),_c('div',{staticClass:"btn-w"},[_c('a-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.fill}},[_vm._v(_vm._s(_vm.l("填充合同")))]),_c('a-button',{attrs:{"size":"small"},on:{"click":_vm.reset}},[_vm._v(_vm._s(_vm.l("重置合同")))])],1),_c('div',{staticClass:"tips"},[_vm._v(" 注意：填充合同之后。若要修改合同信息，必须先重置合同，然后重新填写信息。 ")])],2)]),_c('div',{staticClass:"p-b"})])])}
var staticRenderFns = []

export { render, staticRenderFns }