<template>
  <div>
    <div class="modal-header">
      <div class="modal-title" style="width: 100%; text-align: left">
        <span>签署配置</span>
      </div>
    </div>
    <div style="text-align: center">
      <div style="margin: 20px">
        <a-row type="flex" justify="start">
          <a-col :span="6"> 签署人类型 </a-col>
          <a-col :span="6"> 签署字段名称 </a-col>
          <a-col :span="6"> 签署用户 </a-col>
          <a-col :span="6"> 签署顺序 </a-col>
        </a-row>
      </div>
      <div
        v-for="(item, index) in signingMethods"
        :key="index"
        style="margin: 20px"
      >
        <a-row type="flex" justify="start">
          <a-col :span="6">
            <span v-if="item.signerType === 'Personal'">个人</span>
            <span v-if="item.signerType === 'AgentPersonal'">个人代理人</span>
            <span v-if="item.signerType === 'Organization'">组织架构</span>
            <span v-if="item.signerType === 'Customer'">客户</span>
          </a-col>
          <a-col :span="6">{{
            item.generalFieldsOfAgreementContentName
          }}</a-col>
          <a-col :span="6">
            <div v-if="item.signerType === 'AgentPersonal'">
              <a-input
                style="text-align: center"
                placeholder="点击选择代理人"
                v-model="agentPersonals[item.id].personalName"
                @click="
                  () => {
                    showSelectUser(item.id);
                  }
                "
              />
            </div>
            <div v-if="item.signerType === 'Personal'">{{ personalName }}</div>
            <div v-if="item.signerType === 'Organization'">
              {{ organizationUnitName }}
            </div>
          </a-col>
          <a-col :span="6">{{ item.sort }}</a-col>
        </a-row>
      </div>
    </div>
    <div class="modal-footer">
      <a-button :disabled="saving" @click="close()" type="button">
        <a-icon type="close-circle" />
        {{ l("关闭") }}
      </a-button>
      <a-button :loading="saving" type="primary" @click="save">
        <a-icon type="plus" />
        {{ l("保存") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import ReactTable from "@/components/react-table";
import { CommonServiceProxy } from "@/shared/common-service";
import { message } from "ant-design-vue";
import { AppConsts } from "@/abpPro/AppConsts";
import SelectPageList from "@/components/linggong/select-page-list";

export default {
  mixins: [AppComponentBase, ModalComponentBase],
  name: "signing-configuration",
  components: { ReactTable },

  data() {
    return {
      agentPersonals: {},
      commonService: null,
      signingMethods: [],
      organizationUnitName: "",
      organizationUnitId: "",
      organizationSealId: "",
    };
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  },
  mounted() {
    this.getSigningMethods();
    this.getSigningBodyConfigurations();
  },
  methods: {
    save() {
      //   console.log(this.agentPersonals);
      //   console.log(this.projectAgreementId);
      //   console.log(this.agreementTemplateId);
      //   console.log(this.personalId);
      //   console.log(this.organizationUnitId);
      //   console.log(this.signingMethods);
      const infos = [];
      let emptyCount = 0;
      for (let i = 0; i < this.signingMethods.length; i++) {
        const item = this.signingMethods[i];
        if (item.signerType === "AgentPersonal") {
          const info = this.agentPersonals[item.id];
          if (info.personalId === "") {
            emptyCount++;
            continue;
          }
          infos.push({
            agreementSigningMethodId: item.id,
            signUserId: info.personalId,
            sealId: "",
          });
        }
        if (item.signerType === "Personal") {
          infos.push({
            agreementSigningMethodId: item.id,
            signUserId: this.personalId,
            sealId: "",
          });
        }
        if (item.signerType === "Organization") {
          infos.push({
            agreementSigningMethodId: item.id,
            signUserId: this.organizationUnitId,
            sealId: this.organizationSealId,
          });
        }
      }
      if (emptyCount != 0) {
        abp.message.info("请配置代理人", "系统提示");
        return;
      }
      const params = {
        personalId: this.personalId,
        projectAgreementId: this.projectAgreementId,
        configurationInfos: infos,
      };
      this.saving = true;
      this.commonService
        .post({
          url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/SetSigningConfigurations",
          params: {
            ...params,
          },
        })
        .then(() => {
          this.saving = false;
          //   abp.message.success("配置成功", "系统提示");
          message.success("配置成功");
          this.success(true);
        })
        .catch((err) => {
          console.error(err);
          this.saving = false;
        });
      //   console.log(params);
    },
    showSelectUser(methodId) {
      let vm = this;
      ModalHelper.create(
        SelectPageList,
        {
          title: "选择代理人", // 显示标题
          url: "/api/services/app/UnnaturalPersonProjectPersonnel/GetPersonnelSelectPaged", // 数据接口URL
          otherParams: [
            {
              key: "projectId",
              value: this.projectId,
            },
            {
              key: "isRealNamed",
              value: true,
            },
            {
              key: "ignorePersonalId",
              value: this.personalId,
            },
          ],
          searchPlaceholder: "姓名/手机号/证件号码",
          columnsData: [
            {
              title: "姓名",
              dataIndex: "realName",
              key: "realName",
            },
            {
              title: "手机号",
              dataIndex: "phoneNumber",
              key: "phoneNumber",
            },
            {
              title: "证件号码",
              dataIndex: "idCard",
              key: "idCard",
            },
            {
              title: "实名状态",
              dataIndex: "isRealNamed",
              align: "center",
              width: 75,
              key: "isRealNamed",
              customRender: function (text) {
                var child = vm.$createElement("a-tag", {
                  domProps: {
                    innerHTML: text ? vm.l("RealNamed") : vm.l("NotRealNamed"),
                  },
                  on: {
                    click: function () {},
                  },
                  style: {
                    color: text ? "white" : "red",
                    backgroundColor: text ? "green" : "white",
                  },
                });
                var obj = {
                  children: child,
                  attrs: {},
                };
                return obj;
              },
              scopedSlots: { customRender: "isRealNamed" },
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        if (res && res.success) {
          let data = res.data;
          this.agentPersonals[methodId].personalId = data.personnelId;
          this.agentPersonals[
            methodId
          ].personalName = `${data.realName}(${data.phoneNumber})`;
          this.agentPersonals = this.deepClone(this.agentPersonals);
        }
      });
    },
    getSigningMethods() {
      //   console.log(this.projectAgreementId);
      //   console.log(this.agreementTemplateId);
      //   console.log(this.personalId);
      this.commonService
        .get({
          url: "/api/services/app/AgreementSigningMethod/GetList",
          params: {
            agreementTemplateId: this.agreementTemplateId,
          },
        })
        .then((res) => {
          this.signingMethods = res;
          for (let i = 0; i < res.length; i++) {
            const item = res[i];
            this.agentPersonals[item.id] = {
              id: item.id,
              personalName: "",
              personalId: "",
            };
          }
        });
    },
    getSigningBodyConfigurations() {
      this.commonService
        .get({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/GetSigningBodyConfigurations",
          params: {
            projectAgreementId: this.projectAgreementId,
          },
        })
        .then((res) => {
          //   console.log(res);
          //   this.signingMethods = res;
          const org = res.find((p) => p.signerType === "Organization");
          if (org !== undefined) {
            this.organizationUnitName = org.organizationUnitName;
            this.organizationUnitId = org.organizationUnitId;
            this.organizationSealId = org.organizationSealId;
          }
        });
    },
    deepClone(item) {
      let copy;
      if (item === null || typeof item !== "object") {
        return item;
      }
      // array
      if (item instanceof Array) {
        copy = [];
        let len = item.length;
        for (let i = 0; i < len; i++) {
          // 循环深克隆
          copy[i] = this.deepClone(item[i]);
        }
        return copy;
      }

      // object
      if (item instanceof Object) {
        copy = {};
        for (let val in item) {
          if (item.hasOwnProperty(val)) {
            copy[val] = this.deepClone(item[val]);
          }
        }
        return copy;
      }
      // date
      if (item instanceof Date) {
        copy = new Date();
        copy.setTime(item.getTime());
        return copy;
      }
      throw new Error("Unable to copy item! Its type isn't supported.");
    },
  },
};
</script>

<style scoped lang="less">
</style>